.homeLogoSvgStyle {
  height: 500px;
  width: 100%;
  color: #fff;
}

#homeIcon path {
  stroke-dasharray: 35000;
  stroke-dashoffset: 35000;
  animation: dash 6s linear forwards;
  fill: none;
  stroke: #fff;
  stroke-width: 50px;
  fill-opacity: 1;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
    fill-opacity: 1;
    fill: #fff;
  }
}
